// 👇 I guess the solution could be this but not working: https://github.com/webpack/webpack/issues/11600

// require('dotenv').config({
//   path: `.env.${process.env.NODE_ENV || 'development'}`
// })

module.exports = {
  siteMetadata: {
    title: `Arisa Fukuzaki`,
    description: `Arisa's portfolio website. I'm a DevRel Engineer at Storyblok & an organizer of a free tech skill & knowledge-sharing community, Lilac. My mission: learn, speak, connect & help.`,
    image: `https://a.storyblok.com/f/114452/2857x1607/66c1993ced/blog-hero.png`,
    twitterUsername: `@arisa_dev`,
    siteUrl: `https://arisa-fukuzaki.dev`,
    author: `Arisa Fukuzaki`,
  },
  plugins: [
    `gatsby-plugin-postcss`,
    `gatsby-plugin-dark-mode`,
    `gatsby-plugin-react-helmet`,
    `gatsby-plugin-scroll-reveal`,
    `gatsby-plugin-image`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `gatsby-starter-default`,
        short_name: `starter`,
        start_url: `/`,
        background_color: `#663399`,
        theme_color: `#663399`,
        display: `minimal-ui`,
        icon: `src/images/avocado-favicon.png`, // This path is relative to the root of the site.
      },
    },
    `gatsby-plugin-gatsby-cloud`,
    {
      resolve: 'gatsby-source-storyblok',
      options: {
        accessToken: 'hCcjQtyym7X2KEV4VtPYIwtt',
        version: 'draft',
        resolveRelations: ['Post'],
        // languages: ['de', 'at'] // Optional parameter. Omission will retrieve all languages by default.
      }
    },
    {
      resolve: `gatsby-plugin-google-fonts`,
      options: {
        fonts: [
          `Homemade Apple`,
          `Leckerli One`,
          `Candal`,
          `Balsamiq Sans`
        ],
        display: 'swap'
      }
    },
    {
      resolve: `gatsby-plugin-google-gtag`,
      options: {
        // You can add multiple tracking ids and a pageview event will be fired for all of them.
        trackingIds: [
          process.env.GOOGLE_ANALYTICS_TRACKING_ID, // Google Analytics / GA
        ],
      },
    },
    {
      resolve: `gatsby-plugin-gdpr-cookies`,
      options: {
        googleAnalytics: {
          trackingId: process.env.GOOGLE_ANALYTICS_TRACKING_ID, // leave empty if you want to disable the tracker
          cookieName: `gatsby-gdpr-google-analytics`, // default
          anonymize: true, // default
          allowAdFeatures: false // default
        },
        googleTagManager: {
          trackingId: ``, // leave empty if you want to disable the tracker
          cookieName: `gatsby-gdpr-google-tagmanager`, // default
          dataLayerName: `dataLayer`, // default
        },
        facebookPixel: {
          pixelId: ``, // leave empty if you want to disable the tracker
          cookieName: `gatsby-gdpr-facebook-pixel`, // default
        },
        // defines the environments where the tracking should be available  - default is ["production"]
        environments: [`production`, `development`]
      },
    },
    {
      resolve: 'gatsby-plugin-robots-txt',
      options: {
        host: 'https://arisa-fukuzaki.dev',
        sitemap: 'https://arisa-fukuzaki.dev/sitemap.xml',
        policy: [{ userAgent: '*', allow: '/' }]
      }
    },
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // `gatsby-plugin-offline`,
  ],
}